
import router from "@/router";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { Account } from "@affidaty/t2-lib";
import { useStore } from "vuex";
import { IonContent, IonPage } from "@ionic/vue";
import { BalanceAssetInterface } from "@/models/types";
import { useNumberFormat } from "@marchintosh94/number-format";
import { convertvalueFromBcDecimal } from "@/utils/common";

interface PostMessageData {
  callBack: string;
  args: any;
}
interface PostMessageEvent {
  data: PostMessageData;
}
export default defineComponent({
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const iframeRef = ref();
    const route = useRoute();
    const store = useStore();
    const balance = computed<BalanceAssetInterface[]>(
      () => store.getters.balance
    );
    const language = computed(() => store.getters.language);
    const account = computed<Account>(() => store.getters.account);
    const callBackMap = new Map<string, Function>();
    const iFrameMap = new Map<string, any>();
    const url = ref();
    const target = route.params.coin;
    iFrameMap.set("evocoin", {url: "/assets/external/evocoin/index.html", token: "Qmf1ntErCzop4Cmvg2uTPzn9M1sXDZ2DET8VKcSUxjLndH", thankYouPage: '/evothankyou'});


    /**    CallBack Iframe   */

    callBackMap.set("thankyou", () => {
      router.replace(iFrameMap.get!(target as string).thankYouPage);
    });
    callBackMap.set("iframeReady", () => {
      /// when iframe is ready -> transfer session & cookies
      const iframe = iframeRef.value.contentWindow;
      
      const coin = balance.value.find((el) => el.id == iFrameMap.get!(target as string).token);
      iframe.setupAuthin({
        accountId: account.value.accountId,
        units: useNumberFormat(convertvalueFromBcDecimal(coin?.amount || 0, coin?.decimal || 2)).format(coin?.decimal || 2),
        lang: language.value,
      });
    });

    /**  End CallBack Iframe   */

    if (iFrameMap.has(target as string)) {
      url.value = iFrameMap.get!(target as string).url;
    } else {
      router.replace("/");
    }

    const receiveMessage = (event: PostMessageEvent) => {
      if (callBackMap.has(event.data.callBack)) {
        (callBackMap.get!(event.data.callBack) as Function)(event.data.args);
      }
    };

    onMounted(() => {
      window.addEventListener("message", receiveMessage);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("message", receiveMessage);
    });

    return {
      url,
      iframeRef,
      account,
      balance,
    };
  },
});
